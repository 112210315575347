* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}

body {
    background: var(--color-bg);
}

a {
    text-decoration: none;
    color: unset;
}
.gradient__bg {
    
    /* ff 3.6+ */
    background: -moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 82, 1) 0%, rgba(4, 12, 24, 1) 50%);

    /* safari 5.1+,chrome 10+ */
    background: -webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 82, 1) 0%, rgba(4, 12, 24, 1) 50%);

    /* opera 11.10+ */
    background: -o-radial-gradient(circle at 3% 25%, rgba(0, 40, 82, 1) 0%, rgba(4, 12, 24, 1) 50%);

    /* ie 10+ */
    background: -ms-radial-gradient(circle at 3% 25%, rgba(0, 40, 82, 1) 0%, rgba(4, 12, 24, 1) 50%);

    /* global 92%+ browsers support */
    background: radial-gradient(circle at 3% 25%, rgba(0, 40, 82, 1) 0%, rgba(4, 12, 24, 1) 50%);
}
.side-by-side {
    column-count: auto;
}

.side-by-side > * {
    margin-right: 0.625rem;
}
.price-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 0.625rem;
    text-align: center;
}

.profile-container {
    position: relative;
    top: 1.25rem;
    left: 4rem;
    width: 28%;
    height: 100%;
    padding: 1.25rem;
    background-color: #f2f2f2;
    border-radius: 0.5rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    color: #333;
    float: left;
}

.profile-container > div {
    margin-bottom: 0.625rem;
}

.faq-link {
    font-size: 1.125rem;
    color: #007bff; /* Blue color for the link */
    margin-bottom: 0.3125rem;
    text-align: center;
    text-decoration: none; /* Remove underline by default */
    cursor: pointer; /* Change cursor to pointer */
}

.faq-link:hover {
    text-decoration: underline; /* Underline on hover */
    color: #0056b3; /* Darker blue on hover */
}

.profile-container input[type="text"] {
    padding: 0.3125rem;
    border: 0.0625rem solid #ccc;
    border-radius: 0.25rem;
}

.profile-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 0.625rem;
    text-align: center;
}

.minutes-title {
    font-size: 1.125rem;
    color: #666;
    margin-bottom: 0.3125rem;
}

.name-title {
    font-size: 1.125rem;
    color: #666;
    margin-bottom: 0.3125rem;
}

.email-title {
    font-size: 1.125rem;
    color: #666;
    margin-bottom: 0.3125rem;
}


.login-method-title {
    font-size: 1.125rem;
    color: #666;
    margin-bottom: 0.3125rem;
}

.logout-button {
    background-color: #dc3545;
    color: #fff;
    border: none;
    /* padding: 1rem 2rem; */
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
}

.logout-button:hover {
    background-color: #c82333;
}

.edit-button,
.save-button,
.password-button,
.email-verify-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    cursor: pointer;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
}

.edit-button:hover,
.save-button:hover,
.password-button:hover,
.email-verify-button:hover {
    background-color: #0056b3;
}

.input-field {
    width: 12rem;
    padding: 1rem;
    font-size: 1rem;
    border: 0.0625rem solid #ccc;
    border-radius: 0.25rem;
    margin-top: 0.3125rem;
}
.price-container {
   margin-top: 1.3125rem;
   border-radius: 0.5rem;
   box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
   padding: 1rem;
   float: right;
   width: 65%;
   margin-right: 2%;
   background-color: var(--color-footer);
}

.price-container > * {
   margin-bottom: 2rem;
   padding: 0.5rem 1rem;
}

.email-verify {
    font-size: x-large;
    text-align: center;
    margin-top: 1rem;
}

.centered-button {
    display: flex;
    justify-content: center;
    align-items: center; /* Optional, for vertical centering */
    margin-top: 2rem;
    scale: 1.2;
  }

@media screen and (max-width: 1410px) {
    .price-container {
        float: none;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .profile-container {
        width: 90%;
        /* margin: 8rem; */
        float: none;
        top: 1rem;
        left: auto;
        margin-top: 2rem;
        margin: 0 auto; /* Add this line to center the container */
        padding: 2rem; /* Adjust the padding value as needed */
    }
    .price-container > * {
        margin-bottom: 0rem;
     }
}

@media screen and (max-width: 550px) {
    .price-container {
        float: none;
        width: 100%;
    }
    .profile-container {
        width: 90%;
        float: none;
        top: auto;
        left: auto;
        z-index: 0;
    }
    .price-container > div {
        margin-bottom: 0rem;
     }
     .side-by-side {
        column-count: 1;
        column-gap: 0;
        z-index: 1000;
     }
}

/* .price-container {
   margin-top: 2rem;
   float: right;
   width: 70%
}

@media screen and (max-width: 550px) {
    .price-container {
        float: none;
        width: 100%;
    }
    .profile-container {
        width: 100%;
        float: none;
        top: auto;
        left: auto;
    }

} */