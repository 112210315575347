

.signup-container {
    max-width: 40%;
    margin: 5% auto; /* Center the container */
    padding: 40px; /* Reduce the padding */
    background-color: #f2f2f2;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25); /* Add this line */
}

.signup-container h2 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 36px;
}

.form-group {
    margin-bottom: 10px;
}

.form-group label {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
}

.form-group input {
    width: 100%;
    padding: 16px;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 12px;
}

.form-group a {
    font-size: 16px;
    color: #007bff;
    text-decoration: none;
}
.form-group a:hover {
    text-decoration: underline;
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
    padding: 16px;
    margin-bottom: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
}

.btn:hover {
    background-color: #0056b3;
}

.google-btn {
    background-color: #db4a39;
}
.google-btn:hover {
    background-color: #c23321;
}

.facebook-btn {
    background-color: #3b5998;
}
.facebook-btn:hover {
    background-color: #2d4373;
}

.switch-btn {
    background-color: #dc3545;
}
.switch-btn:hover {
    background-color: #c82333;
}

.password-error-message {
    font-family: var(--font-family);
    font-size: 12px;
}
