.tab-box {
    width: 100%;
    /* max-width: 600px; */
    margin: 0 auto;
    margin-top: 1.5%;
  }
  
  .tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }
  
  .tab-button {
    padding: 8px 16px;
    cursor: pointer;
    border: none;
    background-color: #f0f0f0;
    transition: background-color 0.3s;
    font-family: var(--font-family);
    font-size: 20px;
  }
  
  /* .tabs button.active {
    background-color: #007bff;
    color: white;
    font-weight: bold;
  } */

  .active-tab-0 {
    background-color: #007bff; /* Color for the first tab */
    color: white;
    font-weight: bold;
  }

  .active-tab-0:disabled {
    background-color: #cccccc; /* Gray color */
    color: #666666; /* Darker gray color */
    cursor: not-allowed;
    opacity: 0.6;
  }

  .active-tab-1 {
    background-color: #ff0000; /* Color for the second tab */
    color: white;
    font-weight: bold;
  }

  .active-tab-1:disabled {
    background-color: #cccccc; /* Gray color */
    color: #666666; /* Darker gray color */
    cursor: not-allowed;
    opacity: 0.6;
  }

  .active-tab-2 {
    background-color: #28a745; /* Color for the third tab */
    color: white;
    font-weight: bold;
  }

  .active-tab-2:disabled {
    background-color: #cccccc; /* Gray color */
    color: #666666; /* Darker gray color */
    cursor: not-allowed;
    opacity: 0.6;
  }
  
  .tab-content {
    /* border: 1px solid #ddd; */
    padding: 16px;
    background-color: #fff;
    font-family: var(--font-family);
  }
  
  .drop-box {
    /* Add your drop box styles here */
  }