.submitButtonTranslate {
    margin-top: 2rem;
    align-self: center;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 15px 30px;
    margin-top: 10px;
    background-color: #28a745; /* Green color */
    color: #fff;
    border: none;
    border-radius: 50px; 
    cursor: pointer;
    font-family: var(--font-family);
    transition: all 0.3s;
}

.submitButtonTranslate:disabled {
    background-color: #cccccc; /* Gray color */
    color: #666666; /* Darker gray color */
    cursor: not-allowed;
    opacity: 0.6;
}

.submitButtonTranslate:not(:disabled):hover {
    background-color: #218838; /* Darker green color for hover */
}

/* Add this CSS to your project */
.progress-bar {
    width: 100%;
    height: 20px;
    background-color: #f3f3f3;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;
}

.progress-bar-inner {
    height: 100%;
    background-color: #4caf50;
    width: 0;
    transition: width 0.4s ease;
    border-radius: 5px;
}