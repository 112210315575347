


.product-container {
    background-color: var(--color-footer);
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-items: center; */
    text-align: center;
    width: 100%;
    border-radius: 2.8rem;
    padding: 2rem;
    box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.25);
    margin-bottom: 2rem;
}

.product-title {
    font-size: 1.8rem;
    margin-bottom: 3%;
}

.price-box {
    display: flex;
    align-items: center;
    margin-bottom: 3%;
}

.price {
    font-size: 24px;
    font-weight: bold;
    margin-right: 10px;
}

.product-button {
    background-color: var(--color-subtext);
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 16px;
    cursor: pointer;
}

.product-button:hover {
    background-color: var(--color-signUp);
}

.product-features {
    list-style-type: none;
    padding: 0;
    margin-bottom: 5px;
    font-size: 20px;
}

.product-features li {
    margin-bottom: 5px;
}