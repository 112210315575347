
.product-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10%;
    /* margin-bottom: 5%; */
    justify-items: center;
    align-items: center; 
    text-align: center; 
    margin: 0 10% 25% 10%;
}
.pricing-title {
    font-size: 5rem;
    margin-bottom: 3%;
    font-family: var(--font-family);
    font-weight: 600;
    text-align: center;
}

.pricing-subtitle {
    font-size: 1.5rem;
    margin-bottom: 1em;
    font-family: var(--font-family);
    font-weight: 400;
    text-align: center;
}

.pricing-subtitle a {
    color: "#000";
    text-decoration: none;
}

.pricing-subtitle a:hover {
    color: var(--color-signUp);
    text-decoration: underline;
}

@media screen and (max-width: 650px) {
    /* Customizing feature component as per needs */
    
    .product-grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 0%;
        margin-bottom: 0%;
        justify-items: center;
        align-items: center; 
        text-align: center; 
        margin: 0 10% 5% 10%;
    }
    .pricing-title {
        font-size: 2rem;
        margin-bottom: 3%;
        font-family: var(--font-family);
        font-weight: 600;
        text-align: center;
    }
    .pricing-subtitle {
        font-size: 1.2rem;
        margin-bottom: 8%;
        font-family: var(--font-family);
        font-weight: 400;
        text-align: center;
        margin: 0 10% 10% 10%;

    }
}