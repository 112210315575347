.container {
    border: 2px solid gray; /* Changed dashed to solid */
    padding: 4rem;
    max-width: 60%; /* Updated max-width to take up more of the screen */
    margin: 4rem auto; /* Updated margin to increase the container's height */
    background-color: #f5f5f5;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Added justify-content to center the content vertically */
    font-size: 1.2rem; /* Increased font size */
    justify-content: center;
    text-align: center;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.container.dragging {
    border-color: #007bff;
    font-family: var(--font-family);
}

.container p {
    margin: 10px 0;
    font-family: var(--font-family);
}
/* .container button { 
    margin-top: 2rem;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 15px 30px; 
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 50px; 
    cursor: pointer;
    font-family: var(--font-family);
}
button {
    font-family: var(--font-family);
    margin-top: 10px;
} */

.submitButton {
    margin-top: 2rem;
    align-self: center;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 15px 30px;
    margin-top: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 50px; 
    cursor: pointer;
    font-family: var(--font-family);
    transition: all 0.3s;
}

.submitButton:disabled {
    background-color: #cccccc; /* Gray color */
    color: #666666; /* Darker gray color */
    cursor: not-allowed;
    opacity: 0.6;
}

.submitButton:not(:disabled):hover {
    background-color: #0056b3;
}

.disabledButton {
    margin-top: 2rem;
    align-self: center;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 15px 30px;
    margin-top: 10px; 
    background-color: #ccc;
    color: #fff;
    border: none;
    border-radius: 50px; 
    cursor: pointer;
    font-family: var(--font-family);
    opacity: 0.5; /* Add opacity property to make the button appear greyed out */
    transition: all 0.3s;
}

.insideUploadBox {
    font-family: var(--font-family);
}

.status {
    font-family: var(--font-family);
    font-size: 1.2rem;
    margin-top: 1rem;
}
  label {
    display: block;
    margin-bottom: 5px;
  }
  .numSpeakers {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background: #fff;
    font-size: 16px;
    color: #333;
  }
  
  .numSpeakers:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  }
  
  .numSpeakers::-webkit-inner-spin-button,
  .numSpeakers::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Increase the size of the checkbox */
    /* .p-checkbox .p-checkbox-box {
        width: 25px;
        height: 25px;
    } */
    
    /* Change the color of the checkbox when not checked */
    /* .p-checkbox .p-checkbox-box .p-checkbox-icon {
        border: 2px solid #f00;
    } */
    
    /* Change the color of the checkbox when checked */
    /* .p-checkbox.p-checkbox-checked .p-checkbox-box .p-checkbox-icon {
        border: 2px solid #0f0;
        background-color: #0f0;
    } */
    /* Hide the default checkbox */
.custom-checkbox-container input[type="checkbox"] {
    display: none;
  }
  
  /* Create a new custom checkbox */
  .custom-checkbox-container .custom-checkbox {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #ccc;
    position: relative;
    cursor: pointer;
  }
  
  /* Style the custom checkbox when checked */
  .custom-checkbox-container input[type="checkbox"]:checked ~ .custom-checkbox {
    background-color: #f00;
  }
  
  /* Add a checkmark when checked */
  .custom-checkbox-container input[type="checkbox"]:checked ~ .custom-checkbox:after {
    content: '';
    position: absolute;
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  @media screen and (max-width: 650px) {
    /* Customizing feature component as per needs */
    .container p {
        margin: 10px 0;
        font-size: medium;
    }
    .submitButton {
        margin-top: 2rem;
        font-weight: bold;
        font-size: 1rem;
        padding: 15px 30px;
        margin-top: 20px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 50px; 
        cursor: pointer;
        font-family: var(--font-family);
        transition: all 0.3s;
    }
    .disabledButton {
        margin-top: 2rem;
        font-weight: bold;
        font-size: 1rem;
        padding: 15px 30px;
        margin-top: 10px; 
        background-color: #ccc;
        color: #fff;
        border: none;
        border-radius: 50px; 
        cursor: pointer;
        font-family: var(--font-family);
        opacity: 0.5; /* Add opacity property to make the button appear greyed out */
        transition: all 0.3s;
    }
    .container label {
        font-size: 1rem;
    }
    .container .numSpeakers {
        font-size: 1rem;
    }
    .container {
        max-width: 90%;
    }
}