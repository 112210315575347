* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}

body {
    background: var(--color-bg);
}

a {
    text-decoration: none;
    color: unset;
}
.gradient__bg {
    
    /* ff 3.6+ */
    background: -moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 82, 1) 0%, rgba(4, 12, 24, 1) 50%);

    /* safari 5.1+,chrome 10+ */
    background: -webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 82, 1) 0%, rgba(4, 12, 24, 1) 50%);

    /* opera 11.10+ */
    background: -o-radial-gradient(circle at 3% 25%, rgba(0, 40, 82, 1) 0%, rgba(4, 12, 24, 1) 50%);

    /* ie 10+ */
    background: -ms-radial-gradient(circle at 3% 25%, rgba(0, 40, 82, 1) 0%, rgba(4, 12, 24, 1) 50%);

    /* global 92%+ browsers support */
    background: radial-gradient(circle at 3% 25%, rgba(0, 40, 82, 1) 0%, rgba(4, 12, 24, 1) 50%);
}
.wordDoc {
    flex: 1;
    width: 90%;
    margin: 0 auto;
    padding: 2rem 1rem;
    font-family: var(--font-family);
    font-size: 1.2rem;
    /* line-height: 1.5; */
}
.wordDoc p {
    margin: 1rem 0;
    font-size: 1.3rem;
}

@media screen and (max-width: 650px) {
    
    .wordDoc {
        width: 100%;
        /* margin-left: 10%; */
        /* padding: 1rem 0; */
        font-size: 1rem;
        margin: 0.5rem 0;

    }
    .wordDoc p {
        margin: 0.5rem 0;
        font-size: 1rem;
    }
}