.gpt3__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 6rem;
}

.gpt3__navbar-sign_profile {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    align-items: center;
    justify-items: center;
}

/* .gpt3__navbar-sign_profile {
    margin: 1rem;
} */

/* .gpt3__navbar-sign_profile:hover {
    
    -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
} */

.gpt3__navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.gpt3__navbar-links_logo {
    margin-right: 2rem;
}

.gpt3__navbar-links_logo img {
    width: 120px;
    height: 40px;
    
}

.gpt3__navbar-links_container {
    display: flex;
    flex-direction: row;
}

.gpt3__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.gpt3__navbar-links_container p,
.gpt3__navbar-sign p,
.gpt3__navbar-menu_container p {
    color:#000;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    margin: 0 1rem;
    transition: transform 0.3s ease; /* Add this line */
}

.gpt3__navbar-links_container p:hover,
.gpt3__navbar-sign p:hover {
    color: var(--color-signUp);
    /* text-decoration: underline; */
    /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); */
    transform: scale(1.1); /* Makes the text slightly larger when hovered */
}

.gpt3__navbar-sign button,
.gpt3__navbar-menu_container button {
    padding: 0.5rem 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: #fff ;
    background: var(--color-signUp);
    border-radius: 8px;
    border: 0;
    outline: 0;
    cursor: pointer;
}

.gpt3__navbar-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
}

.gpt3__navbar-menu svg {
    cursor: pointer;
}

.gpt3__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: #fff;
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
    z-index: 9999; /* Add this line to float above everything else */
}

.gpt3__navbar-menu_container p {
    margin: 1rem 0;
}

.gpt3__navbar-menu_container-links-sign {
    display: none;
}

@-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

@media screen and (max-width: 1050px) {
    .gpt3__navbar-links_container {
        display: none;
    }
    .gpt3__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 768px) {
    .gpt3__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__navbar {
        padding: 2rem;
    }

    .gpt3__navbar-sign {
        display: none;
    }
    .gpt3__navbar-menu {
        z-index: 1;
    }

    .gpt3__navbar-menu_container {
        top: 20px;
    }

    .gpt3__navbar-menu_container-links-sign {
        display: block;
        /* flex-direction: column; */
    }
}
