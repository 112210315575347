/* // acceptable audio formats */
ul {
    list-style-type: disc; /* Default style for unordered lists */
  }
.bulleted-list {
    list-style-type: disc; /* Default style for unordered lists */
  }

  div {
    border-top:  none;
    margin: 0
  }

  .aaf-container {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
  }

  h1 {
    color: #333;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
  }
  
  h3 {
    color: #333;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
  }

  .audio-list,
  .video-list {
    margin-right: 20px;
  }
  
  .audio-list li,
  .video-list li {
    margin-bottom: 10px;
    text-align: center;
  }