/* faq.css */
*{
  font-family: var(--font-family);
}

h1 {
    color: #333;
    text-align: center;
    display: flex;
    margin-top: 4rem;
  }
.faqText {
    color: #333;
    text-align: center;
    display: flex;
    margin-top: 4rem;
    font-size: larger;
  }
  .faqTextLittleMargin {
    margin-top: 1rem;
    color: #333;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: larger;
  }
  
  /* ul {
    list-style-type: none;
    padding: 0;
  } */
  .faq-list {
    list-style-type: none;
    padding: 0;
  }
  .faq-item {
    margin: 2rem 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 2rem;
  }
  /* li {
    margin: 2rem 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 2rem;
  } */
  
  .faq-item div {
    cursor: pointer;
  }
  
  .faq-item div span {
    float: right;
  }
  
  /* .faq-item div + div {
    margin-top: 2rem;
    border-top: 1px solid #ddd;
    padding-top: 2rem;
  } */

  .center {
    /* display: flex; */
    justify-content: center;
    align-items: center;
    margin: auto 6rem;
    /* width: 8rem; */
    /* height: 200px; */
    /* border: 3px solid green; */
  }
  .QnA {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto 6rem;
    /* width: 8rem; */
    /* height: 200px; */
    /* border: 3px solid green; */
  }

  .arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.2s ease-in-out;
    font-weight: bold; /* Makes the arrow thicker */
    font-size: 2rem; /* Makes the arrow bigger */
  }
  .arrow:hover {
    color: var(--color-signUp); /* Replace with your desired color */
    }
  
  .rotate-center {
    transform: rotate(180deg);
  }
  .copyButton {
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 1.2rem; /* Add this line */
    padding: 5px 15px; /* Adjust this line */
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 50px; 
    cursor: pointer;
    font-family: var(--font-family);
    transition: all 0.3s;
    align-self: center;
}

.copyButton:hover {
  background-color: #0056b3;
}

/* Download Links */
.download-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.download-link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #fff; /* Change the text color to white */
  text-decoration: none;
  background-color: #007BFF; /* Add a background color */
  padding: 10px 20px; /* Add some padding */
  border-radius: 5px; /* Add rounded corners */
  margin-right: 10px;
  transition: background-color 0.3s ease; /* Add a transition for hover effect */
  
}

.download-link:hover {
  background-color: #0056b3; /* Darken the background color on hover */
}
/* #1976D2
  #FF5722
  #000000
*/
.download-link::before {
  content: "";
  position: absolute;
  left: -25px; /* Move the icon to the left of the button */
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
}

.download-link.text {
  background-color: #fff; /* Change the background color */
  color: #000; /* Change the text color */
  border: 2px solid #000; /* Add a border */
}

.download-link.text:hover {
  background-color: #808080; /* Change the background color */
  color: #fff; /* Change the text color */
}

.download-link.pdf {
  background-color: #BF360C; /* Change the background color */
}
.download-link.pdf:hover {
  background-color: #9E260E; /* Change the background color */
}

.download-link.word {
  background-color: #1565C0; /* Change the background color to dark blue */
}

.download-link.word:hover {
  background-color: #0D47A1; /* Change the background color to darker blue */
}
/* ----------------------------------------------
 * Generated by Animista on 2024-1-7 16:36:29
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
 @-webkit-keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

  @media screen and (max-width: 650px) {
    .center {
      /* display: flex; */
      justify-content: center;
      align-items: center;
      margin: auto 1rem;
      /* width: 8rem; */
      /* height: 200px; */
      /* border: 3px solid green; */
    }
    .QnA {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: auto 1rem;
      /* width: 8rem; */
      /* height: 200px; */
      /* border: 3px solid green; */
    }
    .faqText {
      color: #333;
      text-align: center;
      display: flex;
      margin-top: 4rem;
      font-size: medium;
    }
  }
  